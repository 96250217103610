/**
 * This file contains utility functions for working with group events, helping to resolve
 * the payment recurrence schedule for a group event
 */
import { centsToDollarsFormatted } from '@lib/conversions'

export const ONE_TIME_PAYMENT = 'once'
export const WEEKLY_PAYMENT = 'week'
export const MONTHLY_PAYMENT = 'month'
export const MANUAL_PAYMENT = 'none'
export const PAYMENT_SCHEDULES = [
  ONE_TIME_PAYMENT,
  WEEKLY_PAYMENT,
  MONTHLY_PAYMENT,
  MANUAL_PAYMENT,
]
export const ONLINE_PAYMENT_SCHEDULES = [
  ONE_TIME_PAYMENT,
  WEEKLY_PAYMENT,
  MONTHLY_PAYMENT,
]
export const RECURRING_PAYMENT_SCHEDULES = [WEEKLY_PAYMENT, MONTHLY_PAYMENT]
export const NON_RECURRING_PAYMENT_SCHEDULES = [
  ONE_TIME_PAYMENT,
  MANUAL_PAYMENT,
]

/**
 * Attempts to resolve the payment recurrence schedule for a group event.
 *
 * @param {Object} groupEvent - An object in the shape of a group event.
 * @param {string} [groupEvent.paymentRecurrenceSchedule] - The payment recurrence schedule for the group event,
 * which may be null or undefined for existing group events created before this field was added.
 * @param {boolean} [groupEvent.acceptsOnlinePayments] - Whether or not the group event accepts online payments.
 * @returns {string} The resolved payment recurrence schedule, or the passed-in value if one could not be resolved.
 */
function resolvePaymentRecurrenceSchedule({ paymentRecurrenceSchedule, acceptsOnlinePayments }) {
  if (PAYMENT_SCHEDULES.includes(paymentRecurrenceSchedule)) {
    // If we already have a valid payment recurrence schedule, return it.
    return paymentRecurrenceSchedule
  }

  if (acceptsOnlinePayments !== undefined) {
    // If acceptsOnlinePayments is defined, return one time payment or manual payment.
    // This resolves an implied paymentRecurrenceSchedule for group events created before the field was added.
    return acceptsOnlinePayments ? ONE_TIME_PAYMENT : MANUAL_PAYMENT
  }

  // Otherwise, return the passed-in payment recurrence schedule.
  return paymentRecurrenceSchedule
}

/**
 * Determines whether the given group event has a one-time payment.
 *
 * @param {Object} groupEvent - An object in the shape of a group event
 * @param {string} [groupEvent.paymentRecurrenceSchedule] - The payment recurrence schedule for the group event,
 * which may be null/undefined for existing group events created before this field was added.
 * @param {boolean} [groupEvent.acceptsOnlinePayments] - Whether or not the group event accepts online payments.
 * @returns {boolean} Whether or not the group event has a one-time payment
 */
export function isOneTimePayment({
  paymentRecurrenceSchedule,
  acceptsOnlinePayments,
}) {
  const resovledPaymentRecurrenceSchedule = resolvePaymentRecurrenceSchedule({
    paymentRecurrenceSchedule,
    acceptsOnlinePayments,
  })

  return resovledPaymentRecurrenceSchedule === ONE_TIME_PAYMENT
}

/**
 * Determines whether the given group event has a weekly recurring payment.
 *
 * @param {Object} groupEvent - An object in the shape of a group event
 * @param {string} [groupEvent.paymentRecurrenceSchedule] - The payment recurrence schedule for the group event,
 * which may be null/undefined for existing group events created before this field was added.
 * @param {boolean} [groupEvent.acceptsOnlinePayments] - Whether or not the group event accepts online payments.
 * @returns {boolean} Whether or not the group event has a weekly recurring payment
 */
export function isWeeklyRecurringPayment({
  paymentRecurrenceSchedule,
  acceptsOnlinePayments,
}) {
  const resovledPaymentRecurrenceSchedule = resolvePaymentRecurrenceSchedule({
    paymentRecurrenceSchedule,
    acceptsOnlinePayments,
  })

  return resovledPaymentRecurrenceSchedule === WEEKLY_PAYMENT
}

/**
 * Determines whether the given group event has a monthly recurring payment.
 *
 * @param {Object} groupEvent - An object representing a group event.
 * @param {string} [groupEvent.paymentRecurrenceSchedule] - The payment recurrence schedule for the group event,
 * which may be null/undefined for existing group events created before this field was added.
 * @param {boolean} [groupEvent.acceptsOnlinePayments] - Whether or not the group event accepts online payments.
 * @returns {boolean} Returns true if the group event has a monthly recurring payment, false otherwise.
 */

export function isMonthlyRecurringPayment({
  paymentRecurrenceSchedule,
  acceptsOnlinePayments,
}) {
  const resovledPaymentRecurrenceSchedule = resolvePaymentRecurrenceSchedule({
    paymentRecurrenceSchedule,
    acceptsOnlinePayments,
  })

  return resovledPaymentRecurrenceSchedule === MONTHLY_PAYMENT
}

/**
 * Determines whether the given group event has a manual payment.
 *
 * @param {Object} groupEvent - An object representing a group event.
 * @param {string} [groupEvent.paymentRecurrenceSchedule] - The payment recurrence schedule for the group event.
 * May be undefined or null for existing group events before this field was added.
 * @param {boolean} [groupEvent.acceptsOnlinePayments] - Whether or not the group event accepts online payments.
 * @returns {boolean} Returns true if the group event has a manual payment, otherwise false.
 */
export function isManualPayment({
  paymentRecurrenceSchedule,
  acceptsOnlinePayments,
}) {
  const resovledPaymentRecurrenceSchedule = resolvePaymentRecurrenceSchedule({
    paymentRecurrenceSchedule,
    acceptsOnlinePayments,
  })

  return resovledPaymentRecurrenceSchedule === MANUAL_PAYMENT
}

/**
 * Determines whether the given group event has a recurring payment.
 * @param {Object} groupEvent - An object representing a group event
 * @param {string} [groupEvent.paymentRecurrenceSchedule] - The payment recurrence schedule for the group event.
 * May be undefined or null for existing group events before this field was added.
 * @param {boolean} [groupEvent.acceptsOnlinePayments] - Whether or not the group event accepts online payments.
 * @returns {boolean} Returns true if the group event has a recurring payment, otherwise false.
 */
export function isRecurringPayment({
  paymentRecurrenceSchedule,
  acceptsOnlinePayments,
}) {
  const resovledPaymentRecurrenceSchedule = resolvePaymentRecurrenceSchedule({
    paymentRecurrenceSchedule,
    acceptsOnlinePayments,
  })

  return RECURRING_PAYMENT_SCHEDULES.includes(resovledPaymentRecurrenceSchedule)
}

/**
 * Does the given group event have any payment recurrence schedule that would allow
 * online payments? i.e. one-time, weekly, or monthly
 *
 * @param {Object} groupEvent - An object in the shape of a group event
 * @param {string} groupEvent.[paymentRecurrenceSchedule] - The payment recurrence schedule for the group event,
 * may be null/undefined for existing group events before this field was added
 * @param {boolean} groupEvent.[acceptsOnlinePayments] - Whether or not the group event accepts online payments
 * @returns {boolean} Whether or not the group event uses online payments
 */
export function isOnlinePayment({
  paymentRecurrenceSchedule,
  acceptsOnlinePayments,
}) {
  const resovledPaymentRecurrenceSchedule = resolvePaymentRecurrenceSchedule({
    paymentRecurrenceSchedule,
    acceptsOnlinePayments,
  })

  return ONLINE_PAYMENT_SCHEDULES.includes(resovledPaymentRecurrenceSchedule)
}

/**
 * Creates a formatted display string of the group event's price (i.e: $10 / week / participant ($40))
 *
 * @param {Object} groupEvent - An object in the shape of a group event
 * @param {number} groupEvent.priceInCents - The price of the group event
 * @param {number} [groupEvent.totalPriceInCents] - The total price of the group event, only used for recurring payments
 * @param {string} [groupEvent.paymentRecurrenceSchedule] - The payment recurrence schedule for the group event,
 * may have been null/undefined for existing group events before this field was added
 * @param {boolean} [showTotalPrice=true] - Whether or not to show the total price for recurring payments
 * @returns {string} The formatted display of the group event's price
 */
export function formattedGroupEventPrice(groupEvent, showTotalPrice = true) {
  const pieces = [centsToDollarsFormatted(groupEvent.priceInCents)]

  if (isRecurringPayment(groupEvent)) {
    if (isWeeklyRecurringPayment(groupEvent)) {
      pieces.push('/ week')
    } else if (isMonthlyRecurringPayment(groupEvent)) {
      pieces.push('/ month')
    }

    pieces.push('/ participant')
    if (showTotalPrice) {
      pieces.push(`(${centsToDollarsFormatted(groupEvent.totalPriceInCents)})`)
    }
  }

  return pieces.join(' ')
}
