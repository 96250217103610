import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  photo: {
    position: 'absolute',
    width: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  photoWrapper: {
    borderRadius: 4,
    paddingBottom: '56%',
    marginBottom: '8px',
    position: 'relative',
    overflow: 'hidden',
  },
  squarePhotoWrapper: {
    paddingBottom: '100%',
  },
  cardTitle: {
    fontWeight: 700,
    lineHeight: '28px',
  },
  cardSubtitle: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  horizontalCard: {
    width: '100%',
    paddingLeft: '15px',
  },
  verticalCard: {
    width: '100%',
  },
  cardLink: {
    color: 'black',
    '&:hover': {
      background: '#FCFCFC',
      color: 'black !important',
    },
  },
  chip: {
    position: 'absolute',
    top: '16px',
    left: '5px',
    height: '25px',
    fontSize: '0.8em',
    fontWeight: 'bold',
    zIndex: 2,
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
  },
  cta: {
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
}))

export default useStyles
